// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

import $ from 'jquery'
window.$ = $

document.addEventListener('turbo:load', () => {
  $('#stations_by_state').on('change', function(e) {
    e.stopPropagation();
    abbrev = $('#stations_by_state').val();
    if (abbrev == "all")
      window.location = '/stations';
    else
      window.location = `/stations/${abbrev}`;
  });
  $('[data-href]').on('click', function(e) {
    if ('a' == e.target.tagName.toLowerCase()) return;
    e.stopPropagation();
    Turbo.visit($(this).data('href'));
  });
});

document.addEventListener('turbo:before-fetch-response', (event) => {
  if (event.detail.fetchResponse.statusCode === 413) {
    alert('The file you attempted to upload is too large.  Please upload a smaller file.');
    event.preventDefault();
  }
});
